
import React, { useRef } from "react";

function Button(props) {
  const customClass = `button ${props.className}`;
  const mailto = useRef(null);
  const BookaDemo = () => {
    mailto.current.click();
  };

  return (
    <>
      <button
        className={customClass}
        onClick={props.handleClick ? props.handleClick : BookaDemo}
        type={props.type}
      >
        {props.icon ? <img src={props.icon} /> : ""}
        {props.label}
      </button>
      <a href={props.href} hidden ref={mailto}  target={props.target}/>
    </>
  );
}

export default Button;
