import React, { useRef } from "react";


const File = (props) => {
    const inputEl = useRef(null);
    const openClick = e => {
        inputEl.current.click();
    };
    return (
        <button onClick={openClick} className={props.className}>
            <a href={props.file} target={props.target} ref={inputEl} />
            {props.title}
        </button>
    );
}
export default File;