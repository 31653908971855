import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Monitoring from "../../assets/images/MonitoringFamilyBodyMap.png";
import DetailedAlaram from "../../assets/images/DetailedAlarmLogs.png";
import UserandOrg from "../../assets/images/UserandOrganizationalBuild.png";
import Settings from "../../assets/images/Settingsandbodymap .png";

const Work = () => {
  const data = [
    {
      title: "Monitoring Family’s Body Map",
      content:
        "Read only view to share current state with family members.Remotely view the Body Map, date and time of last update.View alarms that have been triggered.",
      // image: ''
    },
    {
      title: "Detailed Alarm Logs",
      content:
        "Captures detailed data points of each alarm and cancellations, including the body map at the time of event Reports can be produced on demand based on various data elements Filter on User, Alarm Type, Patient ID, date and others.Administrators can download reports to CSV.",
      // image: ''
    },
    {
      title: "User and Organizational Build",
      content:
        "MEIDISHEET provides the organization with a Facility Admin account.The Facility Admin can create nurse accounts, one or many, assigned to floors or buildings.The Admin can create the locations, Floors, Rooms and Bed to fit the Organizational needs.",
      // image: ''
    },
    {
      title: "Settings and body map ",
      content:
        "Ulcer can be set to High, Medium or Low risk with duration up to 2 hours.Absence can be configured for 3 time ranges.The system gives the caregiver the ability to customise settings for each bed.",
      // image: ''
    },
  ];
  return (
    <>
      {/* <Container> */}
      <div className="feature-container">
        <h4>HOW IT WORKS</h4>
        <h2>The Application MEIDISHEET</h2>

        <Row className="container-feature">

          <Col sm={12} md={12}>
            <div className="work-Container2">
              <Row>
                <Col sm={12} md={4} className="workItems">
                  <h4>Detailed Alarm Logs</h4>
                  <ol className="workItems">
                    <li>
                      Captures detailed data points of each alarm and
                      cancellations, including the body map at the time of
                      event.
                    </li>
                    <li>
                      Reports can be produced on demand based on various data
                      elements.
                    </li>
                    <li>
                      Filter on User, Alarm Type, Patient ID, date and others.
                    </li>
                    <li>Administrators can download reports to CSV.</li>
                  </ol>
                </Col>
                <Col sm={12} md={8}>
                  <div className="alaramImg" >
                    <img src={DetailedAlaram} />
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={7}>
            <div className="work-Container">
              <h4>User and Organizational Build</h4>
              <ol>
                <li>
                  MEIDISHEET provides the organization with a Facility Admin
                  account.
                </li>
                <li>
                  The Facility Admin can create nurse accounts, one or many,
                  assigned to floors or buildings.
                </li>
                <li>
                  The Admin can create the locations, Floors, Rooms and Bed to
                  fit the Organizational needs.
                </li>
              </ol>
              <div>
                <img src={UserandOrg} />
              </div>
            </div>
          </Col>
          <Col sm={12} md={5}>
            <div className="work-Container">
              <h4>Settings and body map </h4>
              <ol>
                <li>
                  Pressure Injury Risk can be set to High, Medium or Low risk with duration up
                  to 2 hours.
                </li>
                <li>Absence can be configured for 3 time ranges.</li>
                <li>
                  The system gives the caregiver the ability to customise
                  settings for each bed.
                </li>
              </ol>
              <div>
                <img className="mapimg" src={Settings} />
              </div>
            </div>
          </Col>
        </Row>
      </div>
      {/* </Container> */}
    </>
  );
};
export default Work;
