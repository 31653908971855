import { IconButton, InputBase } from "@mui/material";
import React from "react";
import CustomButton from "../Buttons/Buttons";
export const ReachOut = () => {
  return (
    <div>
      <div className="reachOutToUsBlock">
        <h4>REACH OUT TO US</h4>
        <h2>Learn more about how MEIDISHEET works</h2>
        <div className="emailInput">
          <InputBase sx={{ ml: 1, flex: 1 }} placeholder="Enter Email " />
          <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
          <CustomButton
              label="Book a demo"
              className="whiteButton"
              href={
                "mailto:naveen@nthjen.com?subject=Demo Request"
              }
            />
          </IconButton>
        </div>
      </div>
    </div>
  );
};
