import React, { useEffect, useState } from "react";

export const FlowRepresentation = () => {
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);
  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);
  const FlowRepresentation = [
    {
      image: require("../../Images/meidisheet.png"),
      content:
        "MEIDISHEET uses an industry standard mini computer to gather the pressure data from the bed.",
      arrow: require("../../assets/images/flowArrow.png"),
      mobileArrow: require("../../assets/images/flowArrowDown.png"),
    },
    {
      image: require("../../Images/WiFi.png"),
      arrow: require("../../assets/images/flowArrow.png"),
      mobileArrow: require("../../assets/images/flowArrowDown.png"),
      content:
        "The local Wifi of the facility is used to transport the pressure data to a hosted system to be processed.",
    },
    {
      image: require("../../Images/AWS.png"),
      arrow: require("../../assets/images/flowArrowRight.png"),
      mobileArrow: require("../../assets/images/flowArrowUp.png"),
      content:
        "Amazon Web Services are used to host the system, analyze the data, and trigger alerts.",
    },
    {
      image: require("../../Images/meidisheetWeb.png"),
      content: `MEIDISHEET is a web based application that can be accessed by any device via the browser.  
                      The application securely connects to the hosted servers that process the data.`,
    },
  ];

  return (
    <div className="architecturalFlow">
      <h4>FLOW REPRESENTATION</h4>
      <h2>High level architectural flow</h2>
      <div className="flowRepresentation">
        <div className="row">
          {FlowRepresentation.map((ele, value) => {
            return (
              <div key={value} className="col-md-3 flowContainer">
                <div className="flow">
                  <img src={ele.image} alt="flowImg" className="flowImage" />
                  <p className="flowContent">{ele.content}</p>
                </div>
                <div className="flowArrow">
                  {windowSize[0] < 750 ? (
                    <img src={ele.mobileArrow} className="arrow" alt="" />
                  ) : (
                    <img src={ele.arrow} className="arrow" alt="" />
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
