import React from "react";
export const AboutUsCounter = () => {
  const counter = [
    {
      counterHeader: "2.5 Million",
      counterContent: "People in US develop pressure ulcers",
    },
    {
      counterHeader: "$25 Billion",
      counterContent: "Cost for U.S. care systems to manage pressure injury treatment"
     
    },
    {
      counterHeader: "12.5 Hours",
      counterContent:
        "Average time between welfare checks for elderly and at-risk patients ",
    },
  ];
  return (
    <div className="aboutUsCountSection">
      <div className="aboutUsCounter">
          <h6 className="mb-30" >EACH YEAR</h6>
        </div>
      <div className="row">
        {counter.map((element, value) => {
          return (
            <div key={value} className=" col-md-4 aboutUsCounter">
              <h3>
                {element.counterHeader}
                <span>+</span>
              </h3>
              <p>{element.counterContent}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};
