import React, { useEffect, useState } from "react";
import { Col, Row, Stack } from "react-bootstrap";
import ItemsCarousel from "react-items-carousel";
import RightArrow from "../../assets/images/RightArrowforslider.png";
import LeftArrow from "../../assets/images/LeftArrowforslider.png";
import Lisa from "../../assets/images/lisa.svg";
import Jason from "../../assets/images/jason.svg";
import Quote from "../../assets/images/quote.svg";

const Data = [
  // {
  //   image: Lisa,
  //   name: "Lisa Stickley",
  //   content:
  //     "Wow, we can check if the resident is in their bed and never have to enter the room in the middle of night. MEIDISHEET will help us provide safety, security, and comfort to our residents and family",
  //   designation: "Director of Nursing,The village senior living",
  // },
  {
    image: Jason,
    name: "Jason Wilkinson",
    content:
      "Wow, we can check if the resident is in their bed and never have to enter the room in the middle of night. MEIDISHEET will help us provide safety, security, and comfort to our residents and family",
    designation: "Owner,THE VILLAGE SENIOR LIVING.",
  },
];

const Testimonials = () => {
  const [activeItemIndex, setActiveItemIndex] = useState(0);
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);
  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [Data]);

  const chevronWidth = 40;

  return (
    <>
      <div className="tesimonials-container">
        <h4>TESTIMONIALS</h4>
        {/* <h2>What our users say</h2> */}
        <div style={{ padding: `0 ${chevronWidth}px` }} className="ttwidth">
          {/* <Row> */}
            <ItemsCarousel
              enablePlaceholder={true}
              numberOfPlaceholderItems={4}
              numberOfCards={1}
              gutter={40}
              slidesToScroll={0}
              chevronWidth={10}
              outsideChevron={true}
              showSlither={false}
              firstAndLastGutter={false}
              activeItemIndex={activeItemIndex}
              requestToChangeActive={setActiveItemIndex}
              rightChevron={
                <img
                  src={RightArrow}
                  alt=""
                  style={{ height: 30, position: "absolute", left: 0 }}
                />
              }
              leftChevron={
                <img
                  src={LeftArrow}
                  alt=""
                  style={{ height: 30, position: "absolute", right: 0 }}
                />
              }
            >
              {Data.map((item,index) => (
                <div className="testimonialContentBlock" key={index}>
                  <img
                    alt=""
                    src={item.image}
                    key={item.id}
                    className="testimonialPersonImage"
                  />
                  <div className="testimonialContent">
                    <img alt="" src={Quote} className="quoteimg" />
                    <p className="testimonial">{item.content}</p>
                    <p className="name">{item.name}</p>
                    <p className="designation">{item.designation}</p>
                  </div>
                </div>
              ))}
            </ItemsCarousel>
          {/* </Row> */}
        </div>
      </div>
    </>
  );
};
export default Testimonials;
