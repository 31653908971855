import React, { useEffect, useState } from "react";
import Header from "../Components/Header/Header";
import { Aboutus } from "../Components/Aboutus/Aboutus";
import { AboutUsCounter } from "../Components/Aboutus/AboutUsCounter/AboutUsCounter";
import { WeHelpYou } from "../Components/HelpYou/WeHelpYou";
import { Solution } from "../Components/Solution/Solution";
import { FlowRepresentation } from "../Components/FlowRepresentation/FlowRepresentation";
import { TechnologyPartner } from "../Components/TechnologyPartner/TechnologyPartner";
import Features from "../Components/features/features";
import Work from "../Components/howitWorks/howitWorks";
import Testimonials from "../Components/Testimonials/testimonials";
import Ourbackground from "../Components/OurBackground/ourBackgroun";
import Leaders from "../Components/ourLeaders/leaders";
import { ReachOut } from "../Components/ReachOutToUs/ReachOutToUs";
import AnchorLink from "react-anchor-link-smooth-scroll";
import Logo from "../assets/images/logo.png";
import CustomButton from "../Components/Buttons/Buttons";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Facebook from "../assets/images/fb.png";
import Linkedin from "../assets/images/linkedIn.png";
import Location from "../assets/images/location.png";
import Phone from "../assets/images/phone.png";
function Click() {
  var navbar = document.querySelector(".main-nav ul");
  navbar.classList.toggle("active");
}
function handelClose() {
  var element = document.querySelector(".main-nav ul");
  element.classList.remove("active");
}
const FOOTER_DATA = {
  AboutUsMenu: {
    title: "About Us",
    items: [
      {
        title: "Who we are",
        link: "#aboutus",
      },
      {
        title: "Our Background",
        link: "#ourbackground",
      },
      {
        title: "Testimonials",
        link: "#testimonials",
      },
      {
        title: "Reach out to us",
        link: "#reachout",
      },
    ],
  },
  ProductMenu: {
    title: "Our Product",
    items: [
      {
        title: "We help you with",
        link: "#help",
      },
      {
        title: "How it works",
        link: "#work",
      },
      {
        title: "Product Features",
        link: "#features",
      },
      {
        title: "Solution",
        link: "#solution",
      },
    ],
  },

  ContactUsMenu: {
    title: "Contact Us",
    items: [
      {
        title: "+1 (816) 305-0384",
        link: "#",
        icon: Phone,
      },
      {
        title: `Overland Park, Kansas USA`,
        link: "#",
        icon: Location,
      },
    ],
  },
};
const Home = () => {
  const [state, setState] = useState(false);
  /****Year******/
  const d = new Date();
  let year = d.getFullYear();


  window.onscroll = function (ev) {
    if (window.scrollY > 45) {
      setState(true);
    } else {
      setState(false);
    }
  };
  return (
    <>
      {/* : "main-header bgColor" */}
      <div className="headerContainer" id="header-height">
        <header className={state ? "main-header bgColor" : "main-header  "}>
          <a href="index.html" className="brand-logo">
            <div className="brand-logo-name">
              <img src={Logo} alt="Logo" />
            </div>
          </a>
          <div href="#" className="toggle-button " onClick={Click}>
            <span className="bar"></span>
            <span className="bar"></span>
            <span className="bar"></span>
          </div>
          <nav className="main-nav">
            <ul onClick={handelClose} className="mobilebgColor">
              <li>
                <a href="#">Home</a>
              </li>
              <li>
                <AnchorLink href="#aboutus">Our Product</AnchorLink>
              </li>
              <li>
                <AnchorLink href="#testimonials">Testimonials</AnchorLink>
              </li>
              <li>
                <AnchorLink href="#leaders">Our Team</AnchorLink>
              </li>
              <li>
                <AnchorLink href="#reachout">Contact Us</AnchorLink>
                <a href="#"></a>
              </li>
            </ul>
          </nav>
          <CustomButton
              label="Book a demo"
              className="whiteButton"
              href={
                "mailto:naveen@nthjen.com?subject=Demo Request"
              }
            />
        </header>
        <Header />
      </div>
      <div className="mainContainer">
        <div id="aboutus" className="mt-30"></div>
        <Aboutus />
        <AboutUsCounter />
        <div id="help"></div>
        <WeHelpYou />
        <div id="features"></div>
        <Features />
        <div id="solution"></div>
        <Solution />
        <div id="work"></div>
        <Work />
       
        <div id="ourbackground"></div>
        <Ourbackground />
        <div id="testimonials"></div>
        <Testimonials />
        <div id="flowRepresentation"></div>
        <FlowRepresentation />
        <div id="leaders"></div>
        <Leaders />
        {/* <div id="technologyPartner"></div>
        <TechnologyPartner /> */}
        <div id="reachout"></div>
        <ReachOut />
      </div>
      <div>
        <div className="footerContainer">
          <Row>
            <Col xs={12} sm={12} md={4} lg={3}>
              <img src={Logo} alt="Logo" />
              <div className="socialMediaIcons">
                <a
                  href="https://www.linkedin.com/company/nthjen/?viewAsMember=true"
                  target="_blank"
                >
                  <img src={Linkedin} alt="Linkedin" />
                </a>
                <a
                  href="https://www.facebook.com/NthJEN/?ref=page_internal"
                  target="_blank"
                >
                  <img src={Facebook} alt="Facebook" />
                </a>
              </div>
            </Col>
            <Col xs={12} sm={12} md={8} lg={9}>
              <Row>
                <Col xs={12} sm={12} md={4} lg={4}>
                  {
                    <div className="footerContent" >
                      <h2>{FOOTER_DATA.AboutUsMenu.title}</h2>
                      <ul>
                        {FOOTER_DATA.AboutUsMenu.items.map((item, index) => (
                          <li key={index}>
                            {item.icon && <img src={item.icon} alt="icon" />}
                            <AnchorLink href={item.link}>
                              {item.title}
                            </AnchorLink>
                          </li>
                        ))}
                      </ul>
                    </div>
                  }
                </Col>
                <Col xs={12} sm={12} md={4} lg={4}>
                  {
                    <div className="footerContent">
                      <h2>{FOOTER_DATA.ProductMenu.title}</h2>
                      <ul>
                        {FOOTER_DATA.ProductMenu.items.map((item, index) => (
                          <li key={index}>
                            {item.icon && <img src={item.icon} alt="icon" />}
                            <AnchorLink href={item.link}>
                              {item.title}
                            </AnchorLink>
                          </li>
                        ))}
                      </ul>
                    </div>
                  }
                </Col>
                <Col xs={12} sm={12} md={4} lg={4}>
                  {
                    <div className="footerContent">
                      <h2>{FOOTER_DATA.ContactUsMenu.title}</h2>
                      <ul>
                        {FOOTER_DATA.ContactUsMenu.items.map((item, index) => (
                          <li key={index}>
                            {item.icon && <img src={item.icon} alt="icon" />}
                            {/* <a href={item.link}> */}
                              {item.title}
                            {/* </a> */}
                          </li>
                        ))}
                      </ul>
                    </div>
                  }
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        <div className="footerBottom">
          <Row>
            <Col xs={12} sm={12} md={4} lg={4}>
              <p>All Rights Reserved. &copy; NthJEN, {year}</p>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};
export default Home;
