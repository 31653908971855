import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import BannerImage from "../../assets/images/banner.png";
import CustomButton from "../../Components/Buttons/Buttons";
const Header = () => {
  return (
    <div className="headerBlock">
      <Row>
        <Col>
          <div className="headerContent">
            <h6>Saving Lives with</h6>
            <h2>MEIDISHEET</h2>
            <p>A revolutionary multi-sensor smart bed monitoring system</p>
            <CustomButton
              label="Book a demo"
              className="whiteButton"
              href={
                "mailto:naveen@nthjen.com?subject=Demo Request"
              }
            />
            <div style={{ marginTop: 8 }} className="Attendees">
            {/* <CustomButton
              label="NPIAP Attendees"
              className="whiteButton"
              href={"https://forms.gle/57ipNzpcxhyCQGLH8"}
              traget={"_blank"}
            /> */}
            </div>
          </div>
        </Col>
        <Col className="mobilebannerImage">
          <div style={{ position: "absolute", right: "17%" }}>
            {/* <CustomButton
              label="NPIAP Attendees"
              className="whiteButton"
              href={"https://forms.gle/57ipNzpcxhyCQGLH8"}
              traget={"_blank"}
            /> */}
          </div>
          <div className="bannerImageContainer">
            <img src={BannerImage} alt="" />
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default Header;
