import React, { Fragment } from "react";
import CustomButton from "../../Components/Buttons/Buttons";
import HowitsUsed from "../../assets/pdfs/HowitsUsed.pdf";
import MEIDISHEETDevice from "../../assets/pdfs/MEIDISHEETDevice.pdf";
import WhitePaperMEIDISHEET from "../../assets/pdfs/WhitePaperMEIDISHEET.pdf";

import File from "../file/pdf";

export const Aboutus = () => {
  return (
    <div className="aboutusContainer mt-20">
      <div className="row">
        <div className="col-md-6 aboutusHeading">
          <h6 className="mb-30">MEIDISHEET</h6>
          <h4>Remote patient and resident bed based monitoring solution</h4>
        </div>
        <div className="col-md-6 aboutusContent">
          <p>
            MEIDISHEET is a multi-sensor sheet that lies under a patients
            bedding and monitors vital pressure data. This information is
            provided in real-time to our web-based platform and alerts your
            staff of the potential for pressure injuries and fall risk due to
            unscheduled absence from bed.
          </p>
          <div className="buttonalign">
            <File
              className="openafile"
              target="_blank"
              file={WhitePaperMEIDISHEET}
              title="White Paper"
            />
            <File
              className="openafile"
              target="_blank"
              file={MEIDISHEETDevice}
              title="The Device"
            />
            <File
              className="openafile"
              target="_blank"
              file={HowitsUsed}
              title="How it's Used"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
