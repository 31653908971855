import React from "react";




import { Col, Row, Stack } from "react-bootstrap";




const Ourbackground = () => {

  const data = [

    {

      id: 0,




      heading: "WHY",




      subHead: "MEIDISHEET came to be",




      content:

        "In the ongoing effort to prevent Pressure Injuries, MEIDISHEET is a valuable tool in monitoring a patient’s movement and triggering hospital staff and caregivers of when a patient needs to be repositioned. Repositioning a patient is the #1 most effective way to prevent pressure injuries from happening or to prevent them from getting worse. MEIDISHEET also provides valuable information to caregivers of when a patient or resident has left their bed and not returned.",




      flag: true,

    },




    {

      id: "",




      heading: "",




      subHead: "",




      content: "",




      flag: false,

    },




    {

      id: "",




      heading: "",




      subHead: "",




      content: "",




      flag: false,

    },




    {

      id: 1,




      heading: "WHAT",




      subHead: "we are trying to achieve with MEIDISHEET",




      content:

        "Body mapping is not new and has been available for years, how technology relays the data received is unique. Data tells a story and is beneficial in providing the ultimate care to patients.",




      flag: true,

    },




    {

      id: 2,




      heading: "WHO",




      subHead: "our Founder is",




      content:

        "Naveen Gogumalla is a technology expert who has extensive experience in healthcare. In 2014, Naveen lost his sister at 49 years of age to a heart attack. Naveen realized that with the help of technology, caregivers could have a tool that could prevent injury and provide prevention to those who desperately need it. After several years of planning and preparation, NthJEN was formed in 2017.",




      flag: true,

    },




    {

      id: "",




      heading: "",




      subHead: "",




      content: "",




      flag: false,

    },




    {

      id: "",




      heading: "",




      subHead: "",




      content: "",




      flag: false,

    },




    {

      id: 3,




      heading: "WHERE",




      subHead: "we work on MEIDISHEET",




      content:

        "Our Corporate Office is located in Overland Park, Kansas. Our team consists of technology leaders with HCIT company experience along with other experience including pressure injury prevention for Assisted Living and Care Home facilities.",




      flag: true,

    },




    {

      id: 4,




      heading: "WHEN",




      subHead: "we started MEIDISHEET",




      content:

        "In 2017, Naveen Gogumalla started with a telehealth application and strategy for the physician’s office called MEIDIDOC. In 2018, the company was asked to assist in using technology to help with both pressure injury prevention and fall risk, which in the U.S. is a $50B a year problem.",




      flag: true,

    },

  ];




  let number = [0, 3, 4, 7, 8];




  return (

    <div className="ourBackgroundContainer">
      {/* <h4>OUR BACKGROUND</h4> */}
      <h2>Automated remote care platform</h2>
      <div className="ourBackgroundBlock">
        <Row>
          {data.map((each, index) => {
            return (
              <Col sm={12} md={6} style={{ padding: 0 }} >
                <div className={each.flag ? "wrapper_1" : ''} key={index}  >
                  <div className="contentBlock" >
                    <h3>{each.heading}</h3>
                    <h6>{each.subHead}</h6>
                    <p>{each.content}</p>
                  </div>
                  {number.map((num) => {
                    return (
                      <>
                        {num === index && (
                          <hr className={num % 2 == 0 ? "testright" : "testleft"} />
                        )}
                      </>
                    );
                  })}
                </div>
              </Col>
            );
          })}
        </Row>
      </div>
    </div>
  );
};



export default Ourbackground;