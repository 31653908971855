import React from "react";
import Monitoring from "../../Images/247.svg";
import Identify from "../../Images/identifyRisk.svg";
import Alert from "../../Images/alertstaff.svg";
import Pressure from "../../Images/pressure.svg";
import History from "../../Images/h2.svg";
import Easily from "../../Images/esily.svg";
import Analytics from "../../Images/analytic.svg";
import Alarms from "../../Images/alaram.svg";
import Work from "../../Images/work.svg";
import Bed from "../../Images/bed.svg";
import Body from "../../Images/bodymap.svg";
import UserFriendly from "../../Images/userfriendly.svg";
import Seamless from "../../Images/SeamlessWorking.svg";
import sensor from "../../Images/sensors.png"
import { Col, Container, Grid, Image, Row, Stack } from "react-bootstrap";

const Features = () => {
  const data = [
    {
      Icon: Monitoring,
      feature: "24/7 Monitoring",
      falg: true,
    },
    {
      Icon: Identify,
      feature: "Identify Risk",
      falg: false,
    },
    {
      Icon: sensor,
      feature: "1,052 Sensors",
      falg: false,
    },
    {
      Icon: Alert,
      feature: "Alert Staff",
      falg: false,
    },
    {
      Icon: Pressure,
      feature: "500+ Pressure Sensors",
      falg: false,
    },
    {
      Icon: History,
      feature: "History",
      falg: false,
    },
    {
      Icon: Easily,
      feature: "Easily Scaleable",
      falg: false,
    },
    {
      Icon: Analytics,
      feature: "Analytics",
      falg: false,
    },
    {
      Icon: Alarms,
      feature: "Alert Alarms",
      falg: true,
    },
    {
      Icon: Work,
      feature: "Works on Existing Devices",
      falg: false,
    },
    {
      Icon: Bed,
      feature: "Bed Location Tracking",
      falg: false,
    },
    {
      Icon: Body,
      feature: "Body Map Formation",
      falg: false,
    },
    {
      Icon: UserFriendly,
      feature: "User Friendly",
      falg: false,
    },
    {
      Icon: Seamless,
      feature: "Seamless Integration",
      falg: false,
    },
  ];
  return (
    <>
      <Container>
        <div className=" feature-container feature-container2">
          <h4>FEATURES</h4>
          <h2>What makes us unique</h2>
          <div className="featureItems">
            {data.map((each, index) => {
              return (
                <div
                  key={index}
                  className={each.falg ? "color veriant " : "veriant "}
                >
                  <img src={each.Icon} alt="icons" className="img-pl" />
                  {each.feature}
                </div>
              );
            })}
          </div>
        </div>
      </Container>
    </>
  );
};
export default Features;
