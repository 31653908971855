import React, { useEffect, useState } from "react";
import { Col, Row, Stack } from "react-bootstrap";
import ceoImage from "../../Images/ceo.svg";
import ctoImage from "../../Images/cto.svg";
import seoImage from "../../Images/seo.png";
import RightArrow from "../../assets/images/RightArrowforslider.png";
import LeftArrow from "../../assets/images/LeftArrowforslider.png";
import ItemsCarousel from "react-items-carousel";
import Linkedin from "../../assets/images/linkedIn.png";
import lisa from "../../assets/images/Lisa.png";


const Leaders = () => {
  const [activeItemIndex, setActiveItemIndex] = useState(0);
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);
  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  const chevronWidth = 40;

  const data = [
    {
      name: "Naveen Gogumalla",
      image: ceoImage,
      designation: "Founder CEO",
      linkedin: 'https://www.linkedin.com/in/naveen-gogumalla-7633902b'
    },
    // {
    //   name: "Lisa Chaffins",
    //   image: lisa,
    //   designation: "President ",
    //   linkedin: 'https://www.linkedin.com/in/lisarichmeier'

    // },
    {
      name: "Dwight Sloan",
      image: ctoImage,
      designation: "Chief Operating Officer",
      linkedin: 'https://www.linkedin.com/in/dwightsloan/'

    },
    {
      name: "Jonathan Lindop",
      image: seoImage,
      designation: "Deployment and Support Executive",
      linkedin: 'https://www.linkedin.com/in/jlindop/'
    },
  ];
  return (
    <>
      <div className="leaderContainer">
        <h2>OUR LEADERS</h2>
        {/* <h2>Meet the Leaders that Built Us</h2> */}
        <div style={{ padding: `0 ${chevronWidth}px` }}>
          <Row style={{display:"flex",justifyContent:"center"}}>
            {windowSize[0] < 821 ? (
              <ItemsCarousel
                enablePlaceholder={true}
                numberOfPlaceholderItems={4}
                numberOfCards={1}
                gutter={40}
                slidesToScroll={1}
                chevronWidth={10}
                outsideChevron={true}
                showSlither={false}
                firstAndLastGutter={false}
                activeItemIndex={activeItemIndex}
                requestToChangeActive={setActiveItemIndex}
                rightChevron={
                  <img
                    className="right-arrow"
                    src={RightArrow}
                    alt=""
                    style={{ height: 30, position: "absolute", left: 0 }}
                  />
                }
                leftChevron={
                  <img
                    className="left-arrow"
                    src={LeftArrow}
                    alt=""
                    style={{ height: 30, position: "absolute", right: 0 }}
                  />
                }
              >
                {data.map((each, index) => {
                  return (
                    <Col sm={12} key={index}>
                      <div className="leaderBlock">
                        <div className="top-left"></div>
                        <img src={each.image} className="element-class" />
                      </div>
                      <div className="leaderContent">
                        <a href={each.linkedin} target="_blank" ><img alt="icon" src={Linkedin} /></a>
                        <h5>{each.name}</h5>
                        <h6>{each.designation}</h6>

                      </div>
                    </Col>
                  );
                })}
              </ItemsCarousel>
            ) : (
              data.map((each, index) => {
                return (
                  <Col sm={12} md={3} key={index}>
                    <div className="leaderBlock">
                      <div className="top-left"></div>
                      <img src={each.image} className="element-class" />
                    </div>
                    <div className="leaderContent">
                      <h5>{each.name}</h5>
                      <h6>{each.designation}</h6>
                      <a href={each.linkedin} target="_blank" ><img alt="icon" src={Linkedin} /></a>

                    </div>
                  </Col>
                );
              })
            )}
          </Row>
        </div>
      </div >
    </>
  );
};
export default Leaders;
