import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

export const WeHelpYou = () => {
  const weHelp = [
    {
      image: require("../../Images/PressureUlcers.png"),
      weHelpHeader: "Pressure Injuries",
      weHelpContent: `A person can form a stage II pressure ulcer by laying in the
     same position on the same pressure point without movement in an hour and half.  In the traditional method caregivers have to monitor their
     watches and make the rounds preventing these painful wounds. MEIDISHEET allows the caregiver to be on demand and engage with the person 
     when they need to be repositioned.`,
    },
    {
      image: require("../../Images/Absence.png"),
      weHelpHeader: "Absence",
      weHelpContent: ` "My loved one fell in their bathroom at night and struggled on the floor for multiple 
     hours before someone found them and were able to attend to them", common story.  There are 36 million falls by elders 65 and older per year.
     1 out of 5 of the falls result in broken bones and head injuries and treated in the ER.  MEIDISHEET alerts the caregiver if there is a 
     prolonged absence from the bed when expected.`,
    },
    {
      image: require("../../Images/VisualValidation.png"),
      weHelpHeader: "Visual Validation",
      weHelpContent: `MEIDISHEET gives the caregiver a view of the person on the bed without 
     disturbing them while they sleep or enter the room and cause them to react negatively. Our software will give the  caregivers the ability 
     to validate that their person is where they need to be when they need to be there. MEIDISHEET also allows the family to remotely monitor 
     their loved ones and their current status in bed.`,
    },
  ];
  return (
    <div className="weHelpYou">
      <h4>WE HELP YOU WITH</h4>
      <h2>Keeping uncertainty out of events</h2>
      <div className="weHelp">
        <div className="row">
          {weHelp.map((element, value) => {
            return (
              <div key={value} className="col-md-4">
                <div className="weHelpYouContent">
                  <img src={element.image} alt="photos" />
                  <h3>{element.weHelpHeader}</h3>
                  <p>{element.weHelpContent}</p>
                </div>
              </div>
            );
          })}
        </div>
        {/* <Row>
          <Col>sdf</Col>
          <Col>sdf</Col>
          <Col>sdf</Col>
        </Row> */}
      </div>
    </div>
  );
};
