import React from "react";
import caregiver from "../../Images/caregiver.png";
import shape from "../../Images/forwardArrow.png";

export const Solution = () => {
  const criticalSolution = [
    "Standalone System",
    "Web-based Application",
    "Customized User Management",
    "Independent Operating System",
    "Standard Interface Message to EMR",
    "Track based on location and bed",
    "Does not store PHI",
    "Designed Reports",
  ];
  return (
    <div className="solution">
      {/* <h4>SOLUTION</h4> */}
      <h2>Providing mission critical solutions</h2>
      <div className="row ">
        <div className="solutionContainer">

          <div className="col-lg-5 col-md-12 col-sm-12  ">
            <div className="caregiverImg">
              <img src={caregiver} alt="caregiver" />
            </div>
          </div>
          <div className="col-md-12 col-sm-12 .mt2 col-lg-7">
            <div className="row solution-block">
              {criticalSolution.map((element, value) => {
                return (
                  <div className="col-md-6 " key={value}>
                    <div  className="solutionContent ">
                      <img src={shape} alt="forwardArrow" />
                      <div>{element}</div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
